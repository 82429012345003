<template>  
  <div>
    <v-card>
      <v-card-title class="text-center justify-center">
        <h3 class="font-weight-bold basil--text">
          {{$i18n.t('Documento')}}
        </h3>
      </v-card-title>

      <v-card-text>
        <v-row v-if="loading">
          <v-col cols="12" sm="12" align="center">
            <v-progress-circular
              slot="append"
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-col>
        </v-row>

        <v-row v-if="!loading">
          <v-col cols="12" sm="12" align="right">

            <v-btn
              @click="removendo = true"
              color="red"
              dark
              v-if="documento.id && perfil != 'EMBARCADOR'">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>

            <v-btn
              @click="lista">
              <v-icon>
                mdi-view-headline
              </v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-model="valido"
          v-if="!loading"
          lazy-validation>

          <v-row>
            <v-col cols="12" sm="6">
              <div class="text--primary">
                <label>{{$i18n.t('Transportador')}}</label>
                <div v-if="documento.empresa">{{documento.empresa.nome}}</div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="3" :class="{'error-input': !remetenteValido}">
              <!--
              <label class="mask-label">{{$i18n.t('RemetenteCpfCnpj')}}</label>
              <the-mask
                ref="remetenteCpfCnpj"
                v-model="documento.remetenteCpfCnpj"
                :rules="requiredRules"
                :mask="['###.###.###-##', '##.###.###/####-##', '##.###.###/####-#############']"
                class="mask-input"
                required>
              </the-mask>
              -->
              <v-text-field
                ref="remetenteCpfCnpj"
                v-model="documento.remetenteCpfCnpj"
                :rules="requiredRules"
                :label="$i18n.t('RemetenteCpfCnpj')"
                required>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                ref="remetente"
                v-model="documento.remetente"
                :rules="requiredRules"
                :label="$i18n.t('Remetente')"
                required>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="3" :class="{'error-input': !destinatarioValido}">
              <!--
              <label class="mask-label">{{$i18n.t('DestinatarioCpfCnpj')}}</label>
              <the-mask
                v-model="documento.destinatarioCpfCnpj"
                :rules="requiredRules"
                :mask="['###.###.###-##', '##.###.###/####-##', '##.###.###/####-#############']"
                class="mask-input"
                required>
              </the-mask>
              -->
              <v-text-field
                ref="destinatarioCpfCnpj"
                v-model="documento.destinatarioCpfCnpj"
                :rules="requiredRules"
                :label="$i18n.t('DestinatarioCpfCnpj')"
                required>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                ref="destinatario"
                v-model="documento.destinatario"
                :rules="requiredRules"
                :label="$i18n.t('Destinatario')"
                required>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4">
              <div class="text--primary">
                <v-text-field
                  v-model="documento.numero"
                  :rules="requiredRules"
                  :label="$i18n.t('NumeroNF')"
                  required>
                </v-text-field>
              </div>
            </v-col>

            <v-col cols="4" sm="4">
              <div class="text--primary">
                <v-text-field
                  ref="dataOcorrencia"
                  v-model="documento.dataEmissao"
                  :rules="dataRules"
                  :label="$i18n.t('DateEmissaoNF')"
                  v-mask="'##/##/#### ##:##:##'"
                  required>
                </v-text-field>
              </div>
            </v-col>

            <v-col cols="4" sm="4">
              <div class="text--primary">
                <v-text-field
                  v-model="documento.numeroPedido"
                  :label="$i18n.t('NumeroTransporte')">
                </v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                ref="chave"
                v-model="documento.chave"
                :label="$i18n.t('Chave')">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" v-if="documento.id">
              <div class="text--primary">
                <label>{{$i18n.t('TrackingID')}}</label>
                <div class="chave">{{documento.id}}</div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="6" sm="12">
              <v-text-field
                v-model="documento.cidadeInicio"
                :label="$i18n.t('CidadeInicio')">
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="6" sm="12">
              <v-text-field
                v-model="documento.cidadeFim"
                :label="$i18n.t('CidadeFim')">
              </v-text-field>
            </v-col>
          </v-row>

        </v-form>

        <div class="w100" v-if="!loading && perfil != 'EMBARCADOR'">
          <v-row>
            <v-col align="center" cols="12">
              <v-btn
                color="success"
                elevation="2"
                @click="salva"
                :disabled="!validateForm">
                <v-icon>
                  mdi-check
                </v-icon>
                {{$i18n.t('Salvar')}}
              </v-btn>
            </v-col>
          </v-row>
        </div>

      </v-card-text>

      <v-card-actions v-if="!loading && documento.id && documento.ocorrencias.length > 0">
        <v-row>
          <v-col cols="12" sm="12">
            <div class="reponsive-content">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{$i18n.t('Data')}}
                      </th>
                      <th class="text-left">
                        {{$i18n.t('TipoOcorrencia')}}
                      </th>
                      <th class="text-left">
                        {{$i18n.t('DescricaoOcorrencia')}}
                      </th>
                      <th class="text-left">
                        {{$i18n.t('Situacao')}}
                      </th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="o, i in documento.ocorrencias"
                      :key="o.id"
                      :class="i % 2 == 0 ? 'clickable odd' : 'clickable even'"
                      :style="o.situacao.cor ? 'background: ' + o.situacao.cor : ''">
                      <td @click="visualiza(o)">{{ o.data }}</td>
                      <td @click="visualiza(o)">{{ o.ocorrencia.nome }}</td>
                      <td @click="visualiza(o)">{{ o.descricao }}</td>
                      <td @click="visualiza(o)">{{ o.situacao.nome }}</td>
                      <td>
                        <div class="link" @click="download(o.anexo)" v-if="o.anexo">
                          <span v-if="o.anexo.tipo == 'LINK'">{{ $i18n.t('Abrir') }}</span>
                          <span v-if="o.anexo.tipo == 'IMAGE'">{{ $i18n.t('Download') }}</span>
                        </div>
                      </td>
                      <td>
                        <div class="link" @click="removeOcorrencia(o)" v-if="perfil != 'EMBARCADOR'">
                          {{$i18n.t('Remover')}}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>

      <v-card-actions v-if="!loading && documento.id && perfil != 'EMBARCADOR'">
         <div class="w100">
           <v-row>
              <v-col cols="12" sm="12" align="center">
                <v-btn
                  @click="showNovaOcorrencia"
                  color="success"
                  small
                  v-if="!loading">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                  {{$i18n.t('AdicionarOcorrencia')}}
                </v-btn>
              </v-col>
           </v-row>
         </div>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="visualizando"
      max-width="600px">

      <visualiza
        :ocorrencia="ocorrencia"
        @close="visualizando = false"
        v-if="ocorrencia">
      </visualiza>
      
    </v-dialog>

    <v-dialog
      v-model="removendo"
      persistent
      max-width="290"
      v-if="perfil != 'EMBARCADOR'">
      <v-card>
        <v-card-title class="text-h5">
          {{$i18n.t('RemoveDocumento')}}
        </v-card-title>
        <v-card-text>{{$i18n.t('RemoveDocumentoConfirmacao')}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="removendo = false">
            {{$i18n.t('Nao')}}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="remove">
            {{$i18n.t('Sim')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
      v-if="perfil != 'EMBARCADOR'">
      <v-card>
        <v-card-title class="text-h5">
          {{$i18n.t('RemoveOcorrencia')}}
        </v-card-title>
        <v-card-text>{{ocorrenciaText}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false">
            {{$i18n.t('Nao')}}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmaRemoveOcorrencia">
            {{$i18n.t('Sim')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="adicionando"
      persistent
      max-width="600px"
      v-if="perfil != 'EMBARCADOR' && adicionando">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{$i18n.t('NovaOcorrencia')}}</span>
        </v-card-title>
        <v-card-text>
          <ocorrencia
            ref="novaOcorrencia"
            :documento="documento"
            @close="adicionando = false"
            @salva="adicionaOcorrencia">
          </ocorrencia>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Ocorrencia from './ocorrencias/Ocorrencia.vue';
import Visualiza from '@/pages/documentos/minhas/ocorrencias/Visualiza.vue';
export default {
  components: { Ocorrencia, Visualiza },
    data: () => ({
      id: null,
      loading: false,
      documento: {},
      valido: false,
      perfil: '',
      requiredRules: [
        v => !!v
      ],
      dataRules: [ 
        v => !!v,
        v => (v && v.length == 19) || 'dd-mm-aaaa hh:mi:ss',
      ],
      dialog: false,
      removendo: false,
      ocorrencia: null,
      adicionando: false,
      remetenteValido: true,
      destinatarioValido: true,
      visualizando: false
    }),
    computed: {
      validateForm() {
        return this.documento
          && this.documento.remetenteCpfCnpj != ''
          && this.documento.remetente != ''
          && this.documento.destinatario != ''
          && this.documento.destinatarioCpfCnpj != '';
      },
      ocorrenciaText() {
        if (this.ocorrencia == null)
          return null;

        let t = this.ocorrencia.ocorrencia.nome + ' em ';
        t += this.ocorrencia.data + ' (' + this.ocorrencia.situacao + ')';

        return t;
      }
    },
    methods: {
      load() {
        this.loading = true;
        this.$http.get(`/api/doc/documentos/${this.id}`)
            .then((req) => {
              this.documento = req.data;
              //if (req.data.sucesso) {
                //this.documento = req.data.objeto;
                //if (this.documento == null)
                  //this.$router.push('/error-403');
              //}
              //else
                // this.$msg.erro(req.data.mensagem);
            })
            .catch((e) => {
              this.$msg.erro(e.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });
      },
      lista() {
        this.$router.push('/minhas');
      },
      removeOcorrencia(ocorrencia) {
        this.ocorrencia = ocorrencia;
        this.dialog = true;
      },
      confirmaRemoveOcorrencia() {
        this.dialog = false;
        this.loading = true;
        this.$http.delete(`/api/doc/documento/ocorrencias/${this.ocorrencia.id}`)
          .then((req) => {
            if (req.data.sucesso) {
              this.removeOcorrenciaPorId(req.data.objeto);
            } else
              this.$msg.erro(req.data.mensagem);
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      removeOcorrenciaPorId(id) {
        for (let i = 0; i < this.documento.ocorrencias.length; i++) {
          if (this.documento.ocorrencias[i].id == id) {
            this.ocorrencia = null;
            this.documento.ocorrencias.splice(i, 1);
            this.$msg.sucesso(this.$i18n.t('Sucesso'));
          }
        }
      },
      showNovaOcorrencia() {
        this.adicionando = true;
      },
      adicionaOcorrencia(ocorrencia) {
        if (this.documento.ocorrencias == null)
          this.documento.ocorrencias = [];
        this.documento.ocorrencias.push(ocorrencia);
        this.adicionando = false;
      },
      remove() {
        this.removendo = false;
        this.loading = true;
        this.$http.delete(`/api/doc/documentos/${this.id}`)
          .then((req) => {
            if (req.data.sucesso) {
              this.documento = {};
              this.$msg.sucesso(this.$i18n.t('Sucesso'));
            } else
              this.$msg.erro(req.data.mensagem);
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          })
          .finally(() => {
            this.loading = false;
            this.lista();
          });
      },
      valida() {
        this.remetenteValido = true;
        this.destinatarioValido = true;

        let valido = this.$refs.form.validate();

        /*
        if (this.documento.remetenteCpfCnpj == null
            || (this.documento.remetenteCpfCnpj.length != 11
                && this.documento.remetenteCpfCnpj.length != 14
                && this.documento.remetenteCpfCnpj.length < 14)) {
            this.remetenteValido = false;
        }

        if (this.documento.destinatarioCpfCnpj == null
            || (this.documento.destinatarioCpfCnpj.length != 11
                && this.documento.destinatarioCpfCnpj.length != 14
                && this.documento.destinatarioCpfCnpj.length < 14)) {
            this.destinatarioValido = false;
        }
        */
        
        return valido;
      },
      salva() {
        
        if (this.valida()) {
          this.loading = true;

          let data = {
            "id": this.documento.id,
            "numero": this.documento.numero,
            "chave": this.documento.chave,
            "dataEmissao": this.documento.dataEmissao,
            "remetente": {
              "cpfCnpj": this.documento.remetenteCpfCnpj,
              "nome": this.documento.remetente
            },
            "destinatario": {
              "cpfCnpj": this.documento.destinatarioCpfCnpj,
              "nome": this.documento.destinatario
            },
            "numeroPedido": this.documento.numeroPedido,
            "cidadeInicio": this.documento.cidadeInicio,
            "cidadeFim": this.documento.cidadeFim
          };

          const self = this;
          this.$http.post('/api/doc/documentos', data)
              .then((req) => {
                if (req.data.sucesso) {
                  self.documento = req.data.objeto;
                  self.$msg.sucesso(this.$i18n.t('Sucesso'));
                  self.lista();
                } else
                  self.$msg.erro(req.data.mensagem);
              })
              .catch((e) => {
                self.$msg.erro(e.response.data.message);
                self.focus();
              })
              .finally(() => {
                self.loading = false;
              });
        } else {
          this.focus();
        }
      },
      focus() {
        const self = this;
        setTimeout(function() {
          // self.$refs.remetenteCpfCnpj.$el.focus();
          self.$refs.remetenteCpfCnpj.focus();
        }, 200);
      },
      download(anexo) {
        if (anexo.tipo == 'LINK')
          window.open(anexo.conteudo);

        else if (anexo.tipo == 'IMAGE') {
          this.downloadBase64(anexo.conteudo);
        }
      },
      downloadBase64(base64) {
        let i = base64.indexOf(';base64');
        let ext = null;
        if (base64.startsWith('data:application/'))
          ext = base64.substring(0,i).replace('data:application/', '');
        else
          ext = base64.substring(0,i).replace('data:image/', '');

        let nome = 'Anexo';

        const linkSource = base64;
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);

        downloadLink.href = linkSource;
        downloadLink.target = '_self';
        downloadLink.download = nome.toLowerCase().endsWith('.' + ext.toLowerCase()) ? nome : nome + '.' + ext;
        downloadLink.click();
      },
      visualiza(ocorrencia) {
        this.ocorrencia = ocorrencia;
        this.visualizando = true;
      }
    },
    mounted() {
      this.perfil = this.$auth.info().perfil;

      this.id = this.$route.params.id;
      if (this.id != null) {
        if (this.id === 'add')
          this.documento = {};
        else
          this.load();
      }
      this.focus();
    }
  }
</script>
<style lang="scss" scoped>
  .clickable {
    cursor: pointer;
    
    &.odd {
      background: rgba(0,0,0,0.01);
    }
  }
</style>